.nav-button {
    color: white;
    background-color: transparent;
    border: none;
    margin: auto 2vw;
    text-align: center;
    cursor: pointer;
}

.nav-button.dropdown-button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-grow: 0;
    border: none;
    width: fit-content;
    height: 48px;
    color: black;
    text-decoration: none;
    padding: 0 20px;
    background-color: transparent;
}

.nav-button.dropdown-button:hover {
    color: var(--color-hover);
}

.nav-button.overlay-menu {
    border-bottom: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 4em;
    font-size: 16px;
    width: 100%;
    color: black;
    padding: 1.5em;
    background-color: var(--color-filled);
    text-decoration: none;
    flex-grow: 0;
}

.nav-button.overlay-menu:hover {
    background-color: var(--color-hover);
}

.nav-button.overlay-menu:active {
    background-color: var(--color-active);
}

.nav-button.negative {
    color: indianred;
    font-size: medium;
}

.nav-menu {
    position: relative;
    display: inline-block;
    width: auto;
}

.nav-menu-icon {
    position: relative;
    height: auto;
    width: 2em;
    display: table-cell;
    top: 0;
    border-radius: 50%;
    right: 0;
    cursor: pointer;
    margin: 1em;
}

.nav-dropdown-content {

}

.nav-menu-icon:active {
    background: var(--color-high-emphasis);
}

.nav-menu-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    right: 0;
}

@media screen and (max-width: 1330px) {
    .nav-button {
        margin: 0;
        height: 100%;
    }
}
