input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;

    /*  styling the track  */
    height: 6px;
    background: lightgrey;
    border-radius: 6px;
    width: 90%;
    cursor: pointer;
    outline: none;
}


/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: #054deb;
    border-radius: 50%;
    border: none;

    transition: .2s ease-in-out;
}

/* Thumb: mozilla */
input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #054deb;
    border-radius: 50%;
    border: none;

    transition: .2s ease-in-out;
}

/* Hover, active & focus Thumb: webkit */

input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 5px rgba(45,0,247, .1)
}
input[type="range"]:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(45,0,247, .2)
}
input[type="range"]:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(45,0,247, .2)
}

/* Hover, active & focus Thumb: mozilla */

input[type="range"]::-moz-range-thumb:hover {
    box-shadow: 0 0 0 5px rgba(45,0,247, .1)
}
input[type="range"]:active::-moz-range-thumb {
    box-shadow: 0 0 0 8px rgba(45,0,247, .2)
}
input[type="range"]:focus::-moz-range-thumb {
    box-shadow: 0 0 0 8px rgba(45,0,247, .2)
}