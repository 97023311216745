.autocomplete {
    position: relative;
    display: inline-block;
    width: 75%;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

.autocomplete-item-active {
    background-color: var(--color-active) !important;
    color: #ffffff;
}

.form-container {
    border-radius: 6px;
    padding: 2vmin 3vmin;
    margin: 2vh auto;
    width: 50%;
}

.form-container.none {
    margin: 3vmin 1vmin;
    padding: 2vmin;
    width: 25%;
}

.form-header {
    padding: 1vmin 1vmin;
    margin: 1vh auto;
}

.form-search-field {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: flex-end;
}

.input-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 1em;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.input-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid black;
    border-radius: 3px;
}

.input-checkbox-container:hover input ~ .input-checkmark {
    background-color: var(--color-hover);
}

.input-checkbox-container input:checked ~ .input-checkmark {
    background-color: var(--color-high-emphasis);
}

.input-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.input-checkbox-container input:checked ~ .input-checkmark:after {
    display: block;
}

.input-checkbox-container .input-checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.input-container {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.input-row:after {
    content: "";
    display: table;
    clear: both;
    padding: 0 2vw;
}

.input-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 25%;
    padding: 12px 12px;
    margin-top: 2vh;
    display: inline-block;
}

.input-label.fit-text {
    width: fit-content;
}

.input-label.stay-top {
    align-self: flex-start;
}

.input-label.row-of-two {
    width: 50%
}

.input {
    width: 75%;
    padding: 12px;
    font-size: 90%;
    margin-top: 2vh;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    background-color: white;
}

.input.error {
    box-sizing: border-box;
    border: 1px solid red;
}

.input.text-align-center {
    text-align: center;
}

.input.input-row {
    display: flex;
    display: -ms-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: inherit;
    padding: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
}

.input.no-top-margin {
    margin-top: 0;
}

input.search-field {
    display: inline-block;
    background-image: url("../../public/search.svg");
    background-repeat: no-repeat;
    background-size: 10%;
    background-position: right;
    vertical-align: middle;
    width: auto;
    padding: 0 4px;
    margin: 0.5em;
    min-height: 3em;
    min-width: 25%;
    align-self: center;
}

input.search-field:focus {
    background-image: none;
}

.input.on-panel {
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.input.fit-context {
    width: fit-content;
}

.input.file-input {
    display: none;
}

.input::file-selector-button {
    font-size: 100%;
    min-width: 8em;
    max-width: 8em;
    min-height: 2em;
    border: none;
    border-radius: 10px;
    background-color: var(--color-low-emphasis);
}

.input::file-selector-button:hover {
    background-color: var(--color-hover);
}

.input.fit-container {
    width: 100%;
}

.input.fit-content {
    width: 0;
}

.input::-webkit-inner-spin-button, .input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input[type=number] {
    -moz-appearance: textfield;
}

.input.row-of-two {
    width: 84%;
}

.input.text-area {
    font-size: medium;
}

.input.text-area.adjust-to-content {
    flex: 1 1 auto;
    height: 500px;
    width: 100%;
}

.input.select {
    background-color: lightgrey;
}

.input:focus {
    border-bottom: 1px solid lightgreen;
}

.input.error:focus {
    border-bottom: 1px solid red;
}

.input-redirect-link {
    float: right;
    padding: 1vh 2vw;
}

.input-error {
    margin: 0;
    padding: 0;
    color: indianred;
    width: 100%;
    text-align: right;
}

.input-icon {
    display: table-cell;
    position: relative;
    top: 0;
    margin-top: 2vh;
    border-radius: inherit;
    max-height: fit-content;
    width: 3em;
    border: none;
}

.input-icon.in-context {
    position: static;
    background-color: var(--color-inactive-background);
}

.input-icon.in-context-smaller {
    position: static;
    background-color: var(--color-inactive-background);
    max-height: 50%;
}

.link {
    display: inline-block;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

@media screen and (max-width: 1330px) {
    .input, .input-label, .input-submit, .autocomplete{
        width: 98%;
        margin-top: 0;
        margin-left: 0.4em;
    }

    .input.fit-content {
        margin: 0;
    }

    .input-error {
        width: 98%;
    }

    .input-label {
        padding-left: 6px;
    }

    .input.search-field {
        margin-left: 1em;
    }

    .input-container {
        justify-content: flex-start;
    }

    .input-icon {
        margin-top: 0;
    }

    .input.row-of-two, .input-label.row-of-two {
        width: 95%;
    }

    .form-container, .form-container.none {
        width: 95%;
    }
}
