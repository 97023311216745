@import "input.css";

* {
    box-sizing: border-box;
}

/* Colors
 * main background (other than white) HEX: #3e4444
 * secondary background ---- HEX: #d5e1df
 * main filled ---- HEX: #b5e7a0
 * main focus ---- HEX: #82b74b
 * main active ---- HEX: #86af49
 * main high emphasis --- HEX: #405d27
 */
:root {
    --color-active: #82b74b;
    --color-filled: #b5e7a0;
    --color-header-background: #18260c;
    --color-high-emphasis: #469600;
    --color-hover: #86af49;
    --color-inactive-background: rgba(59, 59, 59, 0.25);
    --color-low-emphasis: #b2b3b1;
    --color-main-background: #d3faaf;
    --color-mild-warn: #fa5563; /* #fa5563 */
    --color-needed: rgba(182, 201, 2);
    --color-outline: whitesmoke;
    --color-page-background: white;
    --color-positive: lightgreen;
    --color-sec-background: #e9f5dc;
    --color-toast-background: rgba(70, 150, 0, 0.6);
    --color-warn: red;
    --color-light-blue: #008cf7;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade-in-below {
    from {
        bottom: -20px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 9%;
        opacity: 0;
    }
    to {
        bottom: 10%;
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        bottom: 9%;
        opacity: 0;
    }
    to {
        bottom: 10%;
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        bottom: 10%;
        opacity: 1;
    }
    to {
        bottom: 9%;
        opacity: 0;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 10%;
        opacity: 1;
    }
    to {
        bottom: 9%;
        opacity: 0;
    }
}

@keyframes foldout {
    0% {
        transform: translateY(-50%) scaleY(0);
    }
    100% {
        transform: translateY(0) scaleY(100%);
    }
}

@-webkit-keyframes foldout {
    0% {
        transform: translateY(-50%) scaleY(0);
    }
    100% {
        transform: translateY(0) scaleY(100%);
    }
}

@keyframes zoom-in {
    0% {
        transform: scaleX(25%) scaleY(25%);
    }
    100% {
        transform: scaleX(100%) scaleY(100%);
    }
}

@keyframes pulse {
    from {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }
    to {
        box-shadow: 0 0 3px 20px rgba(0, 0, 0, 0);
    }
}

@-webkit-keyframes pulse {
    from {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }
    to {
        box-shadow: 0 0 3px 20px rgba(0, 0, 0, 0);
    }
}


@keyframes menu-entry-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

@-webkit-keyframes menu-entry-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}


@keyframes menu-entry-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

@-webkit-keyframes menu-entry-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

.pulse {
    -webkit-animation: pulse 0.7s 5;
    animation: pulse 0.7s 5;
}


.color-header-background {
    background-color: var(--color-header-background);
}

.color-page-background {
    background-color: var(--color-page-background);
}

.color-page-background.text {
    background-color: inherit;
    color: var(--color-page-background);
}

.color-page-background.filter {
    filter: invert(89%) sepia(77%) saturate(2%) hue-rotate(76deg) brightness(110%) contrast(100%);
}

.color-main-background {
    background-color: var(--color-main-background);
}

.color-sec-background {
    background-color: var(--color-sec-background);
}

.color-filled {
    background-color: var(--color-filled);
}

.color-outline {
    background-color: var(--color-outline);
}

.color-high-emphasis {
    background-color: var(--color-high-emphasis);
}

.color-high-emphasis.filter {
    filter: invert(59%) sepia(69%) saturate(6377%) hue-rotate(64deg) brightness(99%) contrast(103%);
}

.color-low-emphasis {
    background-color: var(--color-low-emphasis);
}

.color-needed {
    background-color: var(--color-needed);
}

.color-needed.filter {
    filter: invert(63%) sepia(99%) saturate(520%) hue-rotate(20deg) brightness(98%) contrast(98%);
}

.color-warn {
    background-color: var(--color-warn);
}

.color-warn.filter {
    filter: invert(16%) sepia(79%) saturate(7287%) hue-rotate(5deg) brightness(103%) contrast(122%);
}

.color-mild-warn {
    background-color: var(--color-mild-warn);
}

.color-warn.text {
    background-color: inherit;
    color: var(--color-warn);
}

.color-warn.text-filter {
    background-color: inherit;
    filter: invert(16%) sepia(79%) saturate(7287%) hue-rotate(5deg) brightness(103%) contrast(122%);
}

.color-positive {
    background-color: var(--color-positive);
}

.color-positive.filter {
    background-color: inherit;
    filter: invert(86%) sepia(30%) saturate(642%) hue-rotate(60deg) brightness(100%) contrast(87%);
}

.color-positive.text {
    background-color: inherit;
    color: var(--color-positive);
}


/* Style classes */
hr {
    border-radius: 5px;
    border-top: 1px solid #bbb;
    margin-left: 6px;
    margin-right: 6px;
}

hr.in-content-form {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2vh;
}

.icon-button-on-handheld {

}

.icon-button {
    background-color: inherit;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    height: 32px;
    margin: 0.5em 0.5em;
    max-width: 32px;
    min-width: 32px;
    outline: none;
    padding: 0;
}

.icon-button.smaller-no-margin {
    height: 24px;
    margin: 0;
    max-width: 24px;
    min-width: 24px;
}

.icon-button:hover {
    background-color: var(--color-hover);
}

.icon-button.info {
    background-image: url("../../public/icon_info_outlined.svg")
}

.icon-fit-input-container {
    display: flex;
    height: auto;
    background-color: var(--color-page-background);
    align-items: center;
    border-bottom: 1px groove black;
}

.icon-fit-input-container:focus {
    border-bottom: 1px groove var(--color-positive);
}

.icon {
    border: none;
    border-radius: inherit;
    display: table-cell;
    margin-top: 2vh;
    max-height: fit-content;
    position: relative;
    top: 0;
    width: 3em;
}

.icon.bigger-round {
    max-height: none;
    width: 4em;
    border-radius: 50%;
}

.icon.left-padding {
    padding-left: 0.5em;
}

.icon.in-context-smaller {
    max-height: 40%;
    position: static;
}

.icon.in-context-smaller.warn {
    background-color: var(--color-inactive-background);
}

.icon.in-context.transparent, .icon.in-context-smaller.transparent {
    background-color: inherit;
}

.icon.avatar {
    height: 2em;
    position: relative;
    right: 0;
    top: 0;
}

button, .input-submit {
    background-color: var(--color-filled);
    border-radius: 10px;
    cursor: pointer;
    font-size: 100%;
    margin: 0.5rem 0.5rem;
    max-width: 8em;
    min-height: 2em;
    min-width: 7em;
}

button.inactive,
button.inactive:hover,
button.inactive:active,
.input-submit.inactive,
.input-submit.inactive:hover,
.input-submit.inactive:active {
    background-color: var(--color-inactive-background);
}

button.outline, .input-submit.outline {
    background-color: var(--color-outline);
    border: 1px solid black;
}

button.outline.inherit, .input-submit.outline.inherit {
    background-color: inherit;
}

button.filled, .input-submit.filled {
    background-color: var(--color-filled);
    border: none;
}

button.high-emphasis, .input-submit.high-emphasis {
    background-color: var(--color-high-emphasis);
    border: none;
}

button:hover, .input-submit:hover, .card.clickable:hover {
    background-color: var(--color-hover);
}

button:active, .input-submit:active, .card.clickable:active {
    background-color: var(--color-active);
}

button.fit-small-screens {

}

.drop-button {
    background: white;
    border: 1px groove black;
    border-radius: 0;
    width: 8rem;
}

.drop-button:hover {
    background: var(--color-hover);
}

.dropdown {
    display: inline-block;
    float: right;
    position: relative;
}

.dropdown-content {
    opacity: 0.99;
    background: transparent;
    display: block;
    height: fit-content;
    overflow: auto;
    position: absolute;
    top: 3em;
    padding: 0.2em;
    right: 0;
    width: 50vw;
    z-index: 99 !important;
}

.dropdown-content.smaller {
    right: 1vw;
    width: 25vw;
}

.available {
    flex-grow: 1;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: var(--color-hover);
}

.clickable:active {
    background-color: var(--color-active);
}

.clickable.text-button {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: steelblue;
    padding: 1vh 1vw;
}

.clickable.text-button:hover {
    color: black;
}

.clickable.text-button:active {
    color: black;
}

.bold {
    font-weight: bold;
}

.hide {
    height: 0;
    opacity: 0;
    width: 0;
}

.italic {
    font-style: italic;
}

.box-shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.box-shadow-extra {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.8);
}

.box-radius {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.width-small {
    width: 25%;
}

.width-large {
    width: 75%;
}

.card {
    border-radius: 6px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    line-height: 20px;
    margin: 0;
    min-width: auto;
    padding: 5px 3px;
}

.card-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}

.card-body {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    white-space: pre-line;
}
.card-body.red-line {
    text-decoration: red line-through;
}

.card-body.highlight {
    color: var(--color-high-emphasis);
}

.card-body.note {
    font-size: small;
    font-style: italic;
}

.multiline-box {
    background-color: var(--color-sec-background);
    border: 1px groove var(--color-high-emphasis);
    border-radius: 6px;
    height: 100px;
    margin: 5px 10px;
    padding: 10px 5px;
    width: 50%;

}

.card-header, .card-body {
    border: none;
    min-height: auto;
    overflow: hidden;
    padding: 0 10px;
}

.card-img {
    border: none;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    width: 20%;
}

.center-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center-text {
    text-align: center;
}


.inner-container {
    border: 1px groove grey;
    border-radius: 6px;
    display: flex;
    flex: 1 1 120px;
    flex-direction: column;
    line-height: 20px;
    margin-top: 10px;
    max-height: fit-content;
    max-width: none;
    padding: 5px 3px;
}

.container:after {
    clear: both;
    content: "";
    display: table;
    padding: 0 2vw;
}

.content-box {
    align-items: center;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 3rem;
}

.dialog-overlay {
    align-items: center;
    background: var(--color-inactive-background);
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 100%;
    overflow: auto;
    padding: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.dialog-overlay.invisible {
    background: transparent;
}


.dialog-overlay-menu {
    -webkit-animation: menu-entry-left 0.3s;
    animation: menu-entry-left 0.3s;
    background: var(--color-main-background);
    border: none;
    box-shadow: 2px 0 10px black;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    top: 0;
    visibility: collapse;
    width: 80vw;
    z-index: 2;
}

.dialog-box {
    animation: zoom-in 0.5s ease 1;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    padding: 1rem;
    width: fit-content;
}

.dialog-box-content {
    display: flex;
    display: -ms-flex;
    flex-direction: column;
    margin: 1em;
}

.dialog-box.no-animation {
    animation: none;
}

.dialog-box.adaptable {
    max-height: 85%;
    width: 65%;
}

.error {
    border: 2px groove indianred;
    border-radius: 6px;
    color: indianred;
    font-size: large;
    text-align: center;
    width: 100%;
}

.error.message {
    color: indianred;
    font-size: 100%;
    margin: auto;
    padding: 2vmin 3vmin;
}

.success {
    border: 2px groove limegreen;
    border-radius: 6px;
    color: limegreen;
    font-size: large;
    text-align: center;
    width: 100%;
}

.success.message {
    color: limegreen;
    font-size: 100%;
    margin: auto;
    padding: 2vmin 3vmin;
}

.fixed {
    flex-grow: 1;
    max-height: 30px;
    min-height: 10px;
}

.list {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    list-style-type: none;
    margin: 0;
    overflow: auto;
    padding: 0;
}

.fade-in-below {
    animation-duration: 1s;
    animation-name: fade-in-below;
    animation-timing-function: linear;
    position: relative;
}

.fold-out {
    animation: foldout 0.5s ease 1 alternate;
    position: relative;
}

.list.container {
    border: none;
    border-radius: 6px;
    display: flex;
    min-height: 300px;
    outline: none;
    overflow: visible;
    padding: 1rem;
    width: auto;
}

.list.container.lists {
    padding: 0
}

.list.inner-list {
    border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: auto;
    outline: none;
    padding: 1rem;
}

.list.container.auto-fit {
    flex-grow: 1;
}

.list.container.small {
    width: 25%;

}

.list.container.three-column {
    width: 33%;
}

.unlink {
    color: black;
    text-decoration: none;
}

.dropdown-title {
    border: 1px solid black;
    color: black;
    flex-grow: 1;
    text-align: right;
    width: 100%;
}

.multiline {
    flex: 1 1 auto;
    margin: 0.4em 10px;
    overflow: auto;
    white-space: pre-wrap;
}

.single-line {
    flex: 1 1 auto;
    margin: 0.4em 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.single-line.no-grow {
    flex-grow: 0;
}

.single-line.left-align {
    text-align: left;
}

.single-line.left-margin {
    margin-left: 50px;
}

.title-line {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 5px 10px;
    overflow: visible;
    white-space: nowrap;
}

.title-line.fixed-width {
    width: 15%;
}

.title-line.fill-half {
    flex-grow: 1;
}

.title-line.align-start {
    align-self: flex-start;
}

.support-linebreak {
    color: black;
    margin: 1em 1.5em;
    white-space: pre-wrap;
}

.scrollable {
    height: auto;
    max-height: fit-content;
    min-height: auto;
    overflow: auto;
}

.support-linebreak.fit-card {
    margin: 0.4em 10px;
}

.support-linebreak.bigger-line-space {
    line-height: 1.5;
}

.support-linebreak.adjusted-to-pdf {
    font-size: 90%;
    margin: 1em 1em;
}

.text-right-align {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.border-solid {
    border: 1px solid black;
}

.loader {
    animation: spin 1s linear infinite;
    border: 4px solid lightgrey;
    border-radius: 50%;
    border-right: 4px solid var(--color-high-emphasis);
    border-top: 4px solid var(--color-high-emphasis);
    height: 50px;
    width: 50px;
}

.loader.small {
    border: 2px solid lightgrey;
    border-right: 2px solid var(--color-high-emphasis);
    border-top: 2px solid var(--color-high-emphasis);
    height: 20px;
    width: 20px;
}

.loader.mini {
    border: 2px solid lightgrey;
    border-right: 2px solid var(--color-high-emphasis);
    border-top: 2px solid var(--color-high-emphasis);
    height: 15px;
    width: 15px;
}

.not-found {
    color: indianred;
    font-size: 100%;
    margin: auto;
    padding: 2vmin 3vmin;
    width: 100%;
}

.page-container {
    align-content: space-between;
    align-items: stretch; /* flex-start if not to fit horizontal */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    height: 85vh;
    justify-content: stretch;
    margin: 0;
    overflow: scroll;
    padding: 3rem 1rem;
    width: auto;
}

.page-container.center-content {
    justify-content: center;
}

.page-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.page-content-text {
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    padding: 1em 1em;
    width: 80%;
}

.preview-image {
    background-color: var(--color-inactive-background);
    border: 1px solid black;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 90%;
    height: calc(200 * (56vw / 1070) - 1.2em);
    margin-top: 2vh;
    max-height: 200px;
    max-width: 1070px;
    padding: 0.2em;
    width: calc((1070 * (100% / 1070)));
}

.preview-logo {
    font-size: 90%;
    height: calc(100 / 23 * 1.1725vw);
    position: relative;
    width: calc(100 / 23 * 1.1725vw);
}

.preview-logo.handheld {
    height: calc(100 / 23 * 3.5vw);
    width: calc(100 / 23 * 3.5vw);
}

.preview-pdf-container {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    width: fit-content;
}

.preview-pdf {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: calc(100 / 130 * 1vw);
    height: calc(100 / 23 * 9.9495vw);
    margin: calc(100 / 23 * 0.1vw) auto;
    padding: 1em;
    width: calc(100 / 23 * 7.035vw);
}

.preview-pdf-header {
    height: calc(100 / 23 * 1.181651vw);
    min-width: 0;
    width: calc((100 / 23 * 7.035vw) - 2em);
}

.no-margin {
    margin: 0;
}

.center {
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}

.no-border {
    border: none;
}

.no-outline {
    outline: none;
}

.no-padding {
    padding: 0;
}

.top-padding {
    padding-top: 10px;
}

.start-padding {
    padding-left: 0.25vw;
}

.top-padding.extra {
    padding-top: 20px;
}

.content {
    border: 1px groove grey;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 2em;
    max-height: none;
}

.content.available {
    flex-grow: 1;
}

.content.auto-fit {
    /*noinspection CssRedundantUnit*/
    flex: 1 1 0px;
}

.content-multiple-content {
    background: none;
    border: none;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    max-height: none;
}

.content.invisible {
    border: none;
    box-shadow: none;
}

.content-headline {
    color: black;
    display: flex;
    justify-content: left;
    padding-left: 1em;
}

.content-headline.right-align {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 1em;
    text-align: end;
}

.content-headline.centered {
    justify-content: center;
    margin: 0;
    padding-left: 0;
    text-align: center;
}

.content-headline.underline {
    text-decoration: underline;
}

.content-inner-frame-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
}

.content-inner-frame {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    display: block;
    flex: 1 1 0;
    margin: 2em auto;
    overflow: auto;
}

.content-inner-frame-panel {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

.content-inner-list-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.content-inner-list {
    display: grid;
    gap: 1em;
    grid-template-columns: auto auto auto;
    padding: 1em;
}

.content-inner-list-column {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}

.content-inner-list-column.gap {
    gap: 1em;
}

.content-list {
    display: flex;
    flex-direction: row;
}

.content-list.direction-column {
    flex-direction: column;
}

.content-list > .content, .preview-pdf-container {
    margin: 1em;
}

.content-inner-form {
    border: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 1em;
    width: inherit;
}

.content-inner-form.extra-space {
    gap: 1em;
}

.page-menu-with-content-container {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    height: inherit;
    justify-content: stretch;
    margin: 0;
    padding: 0;
    width: inherit;
}

.content-container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    max-height: 85vh;
    max-width: 75vw;
    min-height: 85vh;
    overflow: auto;
    padding: 1em 3em;
}

.content-container.no-side-menu {
    max-width: 80%;
    padding: 0
}

.content-container.for-page {
    max-height: fit-content;
    min-height: 0;
}

.content-container.for-page.smaller {
    max-width: 50vw;
}

.content-container-headline {
    display: flex;
    justify-content: center;
}

.content-container-headline.center-with-button-on-right {
    margin-left: 7em;
}

.content-control-menu-button {
    border-bottom: 1px solid black;
    border-left: none;
    border-radius: 0;
    border-right: none;
    border-top: none;
    flex-grow: 1;
    flex-shrink: 0;
    height: 4em;
    margin: 0;
    max-width: inherit;
    outline: none;
    width: auto;
}

.content-control-menu-button.fixed-height {
    flex-grow: 0;
}

.content-control-menu-show-button {
    align-items: center;
    -webkit-animation: pulse 0.7s 5;
    animation: pulse 0.7s 5;
    background-color: var(--color-toast-background);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6);
    display: flex;
    height: 26px;
    justify-content: center;
    left: -1em !important;
    margin: 0;
    padding: 0;
    position: absolute !important;
    top: calc(50%) !important;
    transform: rotate(-90deg);
    visibility: collapse;
    width: 60px;
    z-index: 1;
}

.content-control-menu {
    border: none;
    box-shadow: 2px 0 10px black;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: 0;
    width: 25vw;
}

.content-control-menu-floating {
    -webkit-animation: menu-entry-left 0.3s;
    animation: menu-entry-left 0.3s;
    background: var(--color-main-background);
    border: none;
    box-shadow: 2px 0 10px black;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    top: 0;
    visibility: collapse;
    width: 80vw;
    z-index: 2;
}

.row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    width: 100%;
}

.row.fit-content {
    width: auto;
}

.row.space-between {
    justify-content: space-evenly;
}

.row.vertical-expandable {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.column {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.column.align-start {
    align-items: start;
}

.column.align-end {
    align-items: end;
}

.column.no-height {
    align-items: start;
    flex-basis: min-content auto;
    flex-grow: 0;
    flex-shrink: 1;
    height: fit-content;
}

.row.reverse {
    flex-direction: row-reverse;
}

.row.toColumn {

}

.row.stretch {
    align-items: stretch;
}

.row.toColumn.reverse {
    flex-direction: row-reverse;
}

.row.toColumn-on-phones {

}

.row.panel {
    justify-content: space-between;
    padding: 0.5em 0.5em;
}

.row.margin {
    margin-top: 2vmin;
}

.snackbar {
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    background-color: var(--color-toast-background);
    border-radius: 6px;
    bottom: 10%;
    display: block;
    height: 100px;
    left: 50%;
    margin: 0 auto;
    min-width: 20%;
    padding: 25px;
    position: absolute;
    text-align: center;
    transform: translate(-50%);
    z-index: 8;
}

.table-with-content {
    border-collapse: collapse;
    width: 100%;
}

.table-row:nth-child(even) {
    background-color: var(--color-inactive-background);
}

.table-header-row {
    border-bottom: 1px solid black;
}

.table-value {
    border-bottom: 1px groove var(--color-high-emphasis);
    text-align: center;
}

.table-container {
    overflow-x: auto;
}

.table-container.margin-padding {
    border-radius: 6px;
    margin: 2em;
    padding: 2em 0;
}

.table-icon {
    background-color: inherit;
    border: none;
    border-radius: 20px;
    display: block;
    height: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 32px;
    min-width: 32px;
    outline: none;
    padding: 0;
}

.tabs-container {
    background-color: var(--color-sec-background);
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6);
    display: -ms-flex;
    display: flex;
    flex-direction: column;
}

.tabs-container.no-box-shadow {
    box-shadow: none;
}

.tabs-bar {
    border-bottom: 1px ridge grey;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
}

.tabs-bar-button {
    background-color: var(--color-sec-background);
    border: none;
    border-radius: 0;
    height: 48px;
    margin: 0;
    padding: 0;
}

.tabs-bar-button.error {
    border: 1px solid red;
}

.tabs-bar-button.selected {
    background-color: var(--color-active);
}

.tabs-bar-button.top-left-radius {
    -moz-border-radius-topleft: 6px;
    border-start-start-radius: 6px;
}

.tabs-bar-button:hover {
    background-color: var(--color-low-emphasis);
}

.tabs-bar-button:active {
    background-color: var(--color-active);
}

@media screen and (max-width: 916px) {
    button.fit-small-screens {
        max-width: calc(100% - 1rem);
        width: calc(100% - 1rem);
    }

    .list.container.small, .list.container, .list.container.auto-fit {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .dialog-box {
        height: fit-content;
        width: 80%;
    }

    .dialog-box.adaptable {
        max-height: 85vh;
        overflow: auto;
        width: 90%;
    }

    .drop-button {
        margin-left: 1em;
    }

    .card {
        max-width: none;
    }

    .multiline-box {
        width: calc(100% - (10px * 2));
    }

    .page-container {
        margin: auto;
    }

    .row.toColumn {
        align-items: start;
        flex-direction: column;
    }

    .row.toColumn.reverse {
        align-items: end;
        flex-direction: column;
    }

    .row.toColumn-on-phones {
        align-items: start;
        flex-direction: column;
    }

    .content-container {
        min-width: 96vw;
        padding: 3vw;
    }

    .content-container.for-page.smaller {
        max-width: 90vw;
        min-height: fit-content;
    }

    .content-list {
        display: flex;
        flex-direction: column;
    }

    .content-inner-frame-panel {
        align-items: center;
        flex-direction: column-reverse;
    }

    .content-control-menu {
        visibility: collapse;
    }

    .content-control-menu-floating {
        visibility: visible;
    }

    .content-control-menu-show-button {
        visibility: visible;
    }

    .dialog-overlay-menu {
        float: none;
        visibility: visible;
    }

    .dropdown-content {
        right: auto;
        width: 90vw;
    }

    .dropdown-content.smaller {
        right: 1vw;
        width: 50vw;
    }

    .icon-button-on-handheld {
        background-color: inherit;
        border: none;
        border-radius: 20px;
        bottom: 10px;
        height: 64px;
        margin: 0.5em 0.5em;
        max-width: 64px;
        min-width: 64px;
        outline: none;
        padding: 0;
        position: fixed;
        right: 10px;
    }

    .preview-image {
        border: 1px solid black;
        height: calc(200 * (97vw / 1070) - 1.3em);
        margin-left: 10px;
        margin-top: 0;
        width: calc((1070 * (99% / 1070)));
    }

    .preview-logo {
        height: calc(100 / 23 * 2.345vw);
        width: calc(100 / 23 * 2.345vw);
    }

    .preview-pdf {
        border: none;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
        font-size: calc(100 / 95 * 2vw);
        height: calc(100 / 23 * 29.7vw);
        margin: 10px;
        width: calc(100 / 23 * 21vw);
    }

    .preview-pdf-header {
        font-size: 90%;
        height: calc(100 / 23 * 3.5273vw);
        width: calc(100 / 23 * 19.95vw);
    }

    .icon {
        margin-top: 0;
    }

    .icon-button {
        margin: 0.9em 0.9em;
    }

    .single-line {
        margin: 0.4em 10px;
    }

    .single-line.left-margin {
        margin-left: 10px;
    }
}

@media screen and (max-width: 1040px) {
    .content-inner-list {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (1040px <= width <= 1480px) {
    .content-inner-list {
        grid-template-columns: auto auto;
    }
}

@media screen and (915px <= width <= 1330px) {
    .content-list {
        display: flex;
        flex-direction: column;
    }

    .dialog-box {
        height: fit-content;
        width: 80%;
    }

    .drop-button {
        margin: 1em;
    }

    .icon-button {
        margin: 0.9em 0.9em;
    }

    .row.toColumn {
        align-items: start;
        flex-direction: column;
    }

    .row.toColumn.reverse {
        align-items: end;
        flex-direction: column;
    }

    .dialog-box.adaptable {
        width: 80%;
    }

    .content-container.for-page.smaller {
        max-width: 75vw;
    }

    .dialog-overlay-menu {
        visibility: visible;
    }

    .dropdown-content {
        right: calc(-55vw + 40vw);
        width: 60vw;
    }

    .dropdown-content.smaller {
        right: 1vw;
        width: 50vw;
    }

    .preview-image {
        border: 1px solid black;
        height: calc(200 * (75vw / 1070) - 1.2em - 10px);
        margin-left: 10px;
        margin-top: 0;
        width: calc((1070 * (99% / 1070)));
    }

    .preview-logo {
        height: calc(100 / 23 * 2.345vw);
        width: calc(100 / 23 * 2.345vw);
    }

    .preview-pdf {
        border: none;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
        font-size: calc(100 / 95 * 2vw);
        height: calc(100 / 23 * 29.7vw);
        margin: 10px;
        width: calc(100 / 23 * 21vw);
    }

    .preview-pdf-header {
        font-size: 90%;
        height: calc(100 / 23 * 3.5273vw);
        width: calc(100 / 23 * 19.95vw);
    }

    .single-line {
        margin: 0.4em 10px;
    }
}
