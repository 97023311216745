.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  width: 15vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--color-header-background);
  height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-page-container {
  height: 85vh;
  width: 100vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.App-logo-spin {
  animation: App-logo-spin 7s linear infinite;
}

@media screen and (max-width: 1330px) {
  .App-header {
    height: 10vh;
  }

  .App-page-container {
    height: 90vh;
  }
}
