#home {
    align-items: center; /* flex-start if not to fit horizontal */
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    justify-content: center;
    overflow: scroll;
    width: 100vw;
    padding: 2em 0;
}

#home .home-content {
    align-items: center;
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    flex-direction: column;
    width: 85vw;
    border-radius: 6px;
    padding: 2em 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
}

#home .home-content.no-bottom-padding {
    padding-top: 2em;
    padding-bottom: 0;
}

#home .home-text-container {
    align-items: center;
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    flex-direction: column;
    padding-bottom: 2em;
}

#home .subscription-container {
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding-bottom: 2em;
    width: 100%;
}

#home .watermark-container {
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    justify-content: space-evenly;
    width: 100%;
}

#home .watermark-content {

}

#home h1 {
    padding: 1em;
    text-align: center;
}

#home .headline {
    margin: 1em 4em;
}

#home p {
    margin: 1em 5em;
    color: black;
    white-space: pre-wrap;
    line-height: 1.5;
}

#home p.center {
    text-align: center;
}

#home p.bold {
    font-weight: bold;
}

#home p.note {
    font-weight: lighter;
    font-style: italic;
    font-size: smaller;
}

@media screen and (max-width: 1330px) {
    #home {
        padding: 0;
    }

    #home .headline {
        margin: 1em 1.3em;
    }

    #home .home-content {
        width: 100vw;
        min-height: 90vh;
    }

    #home .subscription-container {
        padding: 2em 2em;
        gap: 2em;
    }

    #home p {
        margin: 1em 1.5em;
    }
}