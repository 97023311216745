#suggestion-box {}

#suggestion-box .suggestion-container {
    position: absolute;
    display: flex;
    visibility: hidden;
    overflow: scroll;
    flex-direction: column;
    width: 100%;
    z-index: 1000;
    transition-property: visibility;
    transition-duration: .1s;
    transition-timing-function: step-end;
}

#suggestion-box div:focus-within + .suggestion-container {
    visibility: visible;
}

#suggestion-box .suggestion-card {
    display: flex;
    display: -ms-flex;
    gap: 1em;
    background-color: var(--color-page-background);
    border-radius: 0;
    font-size: 12px;
    border-bottom: 1px groove grey;
    padding-left: 12px;
    cursor: pointer;
}

#suggestion-box .suggestion-card:hover {
    background-color: var(--color-hover);
}

#suggestion-box .suggestion-card:active {
    background-color: var(--color-active);
}

@media screen and (max-width: 1330px) {
    #suggestion-box .suggestion-card {
        width: 98%;
        margin-left: 0.4em;
        gap: 0;
        flex-direction: column;
    }
}

