#verification-dialog {
    animation: zoom-in 0.5s ease 1;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    padding: 1em;
    width: auto;
}

#verification-dialog .text {
    white-space: pre-wrap;
    text-align: center;
    font-size: 18px;
}

#verification-dialog .input-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: center;
    border-radius: 6px;
    padding: 0.2em;
    cursor: pointer;
    pointer-events: bounding-box;
}

#verification-dialog .input-container.error {
    border: 1px solid var(--color-warn);
}

#verification-dialog .button-container {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-start;
    margin-top: 2em;
}


#verification-dialog input {
    width: 30px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    margin: 0 10px;
    text-align: center;
    font-size: 30px;
    pointer-events: none;
    cursor: not-allowed;
}

#verification-dialog input:focus {
    border-bottom: 1px solid var(--color-positive);
    outline: none;
}

#verification-dialog .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


